import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { State as StateClass } from 'vuex-class/lib/bindings';
import { State } from '@/store/models';
import { Getter } from 'vuex-class';

@Component({})

export default class CheckoutFooter extends Vue {
  @Prop({ default: true }) invalid!: Boolean;
  @Prop({ default: undefined }) back!: string | undefined;
  @Prop({ required: true }) buttonText!: string;
  @Prop({ required: true }) buttonNote!: string;
  @Prop({ required: true }) onClick!: Function;

  @StateClass user!: State['user'];
  @Getter isEligibleToInvest!: boolean;
}
