import { Vue, Prop, Component } from 'vue-property-decorator';
import { Asset } from '@/store/models/asset';
import Loader from '@/components/common/loader/Loader.vue';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import FundDocs from '@/components/common/fund-docs/FundDocs.vue';
import ProgressBarSmall from '@/components/common/progress-bar-small/ProgressBarSmall.vue';

@Component({
  components: {
    Loader,
    WithDownloads,
    FundDocs,
    ProgressBarSmall,
  },
})

export default class PropertiesGridFund extends Vue {
  @Prop({ default: (): undefined => undefined }) asset!: Asset;

  get assetAddress(): string {
    if (!this.asset) {
      return '';
    }

    return this.asset.street && this.asset.houseNumber && this.asset.postalCode && this.asset.city
      ? `${this.asset.street} ${this.asset.houseNumber}, ${this.asset.postalCode}, ${this.asset.city}`
      : this.asset.city;
  }

  get placeHolderImage(): Object {
    return require('@/assets/images/property/properties-placeholder.jpg');
  }
}
