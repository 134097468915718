import { Component, Ref, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, State as ClassState } from 'vuex-class';
// @ts-ignore
import { ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
import VueNumberInput from '@chenfengyuan/vue-number-input';
import Loader from '@/components/common/loader/Loader.vue';
import { State } from '@/store/models';
import { Asset } from '@/store/models/asset';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import CheckoutInvestmentInfo from '@/components/checkout/investment/info/CheckoutInvestmentInfo.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import FormInvalidMessage from '@/components/common/form-elements/FormInvalidMessage.vue';
import { formatNumber } from '@/filters/number';
import CheckoutFooter from '@/components/checkout/footer/CheckoutFooter.vue';
import { Checkout } from '@/store/models/checkout';
import { PaymentInitData } from '@/store/modules/payment';
import { intercom, requireContractAgreement } from '../../../../whitelabel.config';

@Component({
  components: {
    Loader,
    WithDownloads,
    CheckoutInvestmentInfo,
    [VueNumberInput.name]: VueNumberInput,
    ValidationProvider,
    ValidationObserver,
    FormInvalidMessage,
    CheckoutFooter,
  },
})

export default class CheckoutInvestment extends Vue {
  amountEur: number = 0;
  selectedDividendsFormatYear: [string, number] = ['0', 0];
  // Based on the whitelabel.config show/skip the contract agreement step
  requireContractAgreement: boolean = requireContractAgreement;
  formatNumber = formatNumber;

  @Action(addToastMessage) addToastMessage!: Function;
  @Action paymentInit!: (data: PaymentInitData) => void;
  @Action resetPayment!: Function;
  @Action updateCheckoutAction!: Function;
  @Action resetCheckoutAction!: (initValue?: Partial<Checkout>) => void;

  @ClassState payment!: State['payment'];
  @ClassState idin!: State['idin'];
  @ClassState user!: State['user'];

  @Ref('form') readonly form!: InstanceType<typeof ValidationObserver>;

  @Getter getAssetById!: (string) => Asset;
  @Getter isEligibleToInvest!: boolean;
  @Getter getCheckout!: Checkout;
  @Getter('getAssetAddress') assetAddress!: string;

  mounted(): void {
    const resetStore = this.fundId !== this.getCheckout.assetId;
    if (resetStore) {
      this.resetCheckoutAction({
        eurAmount: this.inputMin,
      });
    }
    this.amountEur = this.getCheckout!.eurAmount;
    this.selectedDividendsFormatYear = this.getCheckout!.selectedDividendsFormatYear;
    if (!resetStore) {
      this.form.validate();
    }
  }

  created(): void {
    this.resetPayment();
  }

  @Watch('selectedDividendsFormatYear')
  onselectedDividendsFormatYearChange(): void {
    this.$gtm.trackEvent({
      event: 'invest_select',
      email: this.user?.email,
      property: this.asset?.name,
      investment: this.totalEur,
      rent: this.selectedDividendsFormatYear,
    });
  }

  @Watch('isGTMDataAvailable', { immediate: true })
  changeToisGTMDataAvailable(val, oldVal): void {
    if (val && !oldVal) {
      this.$gtm.trackEvent({
        event: 'invest_visit',
        email: this.user?.email,
        property: this.asset?.name,
      });
    }
  }

  get isGTMDataAvailable(): boolean {
    return (!!this.user?.email && !!this.asset?.name);
  }

  get inputMin(): number {
    return (this.asset && this.asset.euroMin) || 0;
  }

  get inputMax(): number {
    if (!this.asset) {
      return 0;
    }
    const calculated = this.asset.sharesAvailable * this.asset.sharePrice;
    const hardcoded = 1_000_000;
    return calculated > hardcoded ? hardcoded : calculated;
  }

  /**
   * When to disable the payment button.
   */
  get disablePaymentButton(): boolean {
    return !!this.paymentLoading || !this.asset || !!this.paymentSuccess || this.asset.sharesAvailable <= 0;
  }

  get fundId(): string {
    return this.$route.params.id;
  }

  get asset(): Asset | undefined {
    return this.getAssetById(this.fundId);
  }

  get emissionCost(): number {
    return this.asset?.emissionCost || 0;
  }

  get totalEmissionCosts(): number {
    // If the excessEmission object is provided then check its threshold and only add the excessEmission
    // percentage if the selected euro amount exceeds it
    if (this.asset?.excessEmission && this.amountEur >= this.asset.excessEmission.threshold) {
      return (this.amountEur * this.asset.excessEmission.percentage) / 100;
    }

    return this.emissionCost ? (this.amountEur * this.emissionCost) / 100 : 0;
  }

  get totalEur(): number {
    return this.totalEmissionCosts + this.amountEur;
  }

  get amountShares(): number {
    return this.amountEur / this.asset!.sharePrice;
  }

  get placeHolderImage(): Object {
    return require('@/assets/images/property/properties-placeholder.jpg');
  }

  /**
   * Returns whether payment is loading/processing.
   *
   * @returns {boolean}
   */
  get paymentLoading(): boolean {
    return !!this.payment && this.payment.status === 'processing';
  }

  /**
   * Returns whether payment is a success.
   *
   * @returns {boolean}
   */
  get paymentSuccess(): boolean {
    return !!this.payment && this.payment.status === 'success';
  }

  get investorLoading(): boolean {
    return !this.user;
  }

  updateAsset(newValue, oldValue): void {
    this.$gtm.trackEvent({
      event: 'invest_select',
      email: this.user?.email,
      property: this.asset?.name,
      investment: this.totalEur,
      rent: this.selectedDividendsFormatYear,
    });
  }

  startCheckout(): void {
    this.updateCheckoutAction({
      assetId: this.fundId,
      eurAmount: this.amountEur,
      selectedDividendsFormatYear: this.selectedDividendsFormatYear,
      sharesAmount: this.amountShares,
      totalEur: this.totalEur,
      totalEmissionCosts: this.totalEmissionCosts,
    });
    if (this.requireContractAgreement) {
      this.$router.push({ path: `/${this.$route.params.lang}/checkout/legal/${this.fundId}/` });
    } else {
      this.paymentInit({
        redirectUrl: `${window.location.origin}/checkout/status/`,
        lang: localStorage.getItem('vue-i18n-language') || 'nl',
        brand: require('../../../../whitelabel.config').brand,
      });
    }
  }

  intercom = intercom;
}
