import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter, State as StateClass } from 'vuex-class';
import { State } from '@/store/models';

@Component
export default class FundDocs extends Vue {
  @Prop() downloads;
  @Getter getAssetById!: Function;
  @StateClass user!: State['user'];

  get prospectus(): [string, string][] {
    return (this.downloads && this.downloads.prospectus) || [];
  }

  get brochure(): [string, string][] {
    return (this.downloads && this.downloads.brochure) || [];
  }

  get mixedFiles(): [string, string][] {
    return this.prospectus.concat(this.brochure);
  }

  getFilename(path: string): string {
    const splittedPath = path.split('/');
    return splittedPath[splittedPath.length - 1];
  }

  clickedOnDownload(mixedFile): void {
    this.$gtm.trackEvent({
      event: 'download_file',
      email: this.user?.email,
      property: this.getAssetById(mixedFile[0].substring(7, 27)).name, // gives the ID
      file: mixedFile[0].substring(28), // fileName
    });
  }
}
