import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import { Vue, Component } from 'vue-property-decorator';
import CheckoutFooter from '@/components/checkout/footer/CheckoutFooter.vue';
import { State as ClassState, Getter, Action } from 'vuex-class';
import { State } from '@/store/models';
import { BusinessInvestor, PrivateInvestor } from '@/store/models/user';
import { formatNumber } from '@/filters/number';
import { Checkout } from '@/store/models/checkout';
import { Asset } from '@/store/models/asset';
import { PaymentInitData } from '@/store/modules/payment';

interface ContractData {
  name: string,
  surname: string,
  dateOfBirth: number,
  streetAddress: string,
  postalCode: string,
  city: string,
  email: string,
}

@Component({
  components: {
    CheckoutFooter,
    WithDownloads,
  },
})

export default class CheckoutLegal extends Vue {
  formatNumber = formatNumber;

  @Getter isEligibleToInvest!: boolean;
  @Getter getCheckout!: Checkout;
  @Getter getAssetById!: (string) => Asset;

  @ClassState user!: State['user'];

  @Action paymentInit!: (data: PaymentInitData) => void;

  get asset(): Asset | undefined {
    return this.getAssetById(this.getCheckout?.assetId);
  }

  get contractData(): ContractData | null {
    if (!this.user) {
      return null;
    }

    // NOTE: Since there is no iDIN in Randstad the user was asserted to be of private or business types. This was necessary as
    // the `name` field is required for the contract data
    const { name, surname, email, dateOfBirth, city, postalCode, streetAddress } = this.user as PrivateInvestor | BusinessInvestor;

    return {
      name,
      surname,
      email,
      dateOfBirth: dateOfBirth.toMillis(),
      city,
      postalCode,
      streetAddress,
    };
  }

  initPayment(): void {
    this.paymentInit({
      redirectUrl: `${window.location.origin}/checkout/status/`,
      lang: localStorage.getItem('vue-i18n-language') || 'nl',
      brand: require('../../../../whitelabel.config').brand,
    });
  }
}
