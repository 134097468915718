import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class ProgressBarSmall extends Vue {
  @Prop({ default: 100 })
  sold!: number;

  @Prop({ default: 1000 })
  supply!: number;

  @Prop({ default: 0 })
  pending!: number;

  /**
   * Percentage of Shares sold.
   */
  get percentageSold(): number {
    const supply: number = this.supply === 0 ? 1 : this.supply;
    const sold: number = this.sold === 0 ? 1 : this.sold;

    // Round to 2 decimals
    return Math.round(((sold / supply) * 100) * 100) / 100;
  }

  /**
   * Percentage of Shares currently selected
   * by the investor to buy.
   */
  get percentagePending(): number {
    const supply: number = this.supply === 0 ? 1 : this.supply;
    const pending: number = this.pending === 0 ? 1 : this.pending;

    return (pending / supply) * 100;
  }

  /**
   * Position bar to indicate current percentage sold,
   * but take presentation into account, i.e. never
   * go lover than 2 and never higher than 98 percent.
   */
  get percentageLeftOffset(): number {
    return Math.max(Math.min(this.percentageSold, 98), 2);
  }

  get shouldShowProgress(): boolean {
    return this.percentageSold >= 80;
  }
}
